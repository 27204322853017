<template lang="pug">
#user
    .head
        router-link.headbox(to="/personnal") 
            .imgbox
                img(:src="head_img_url")
            .right-box 
                span.name {{ username }}
                span.look 查看个人资料

    .list 
        .item.mb12.order(@click="order()") 我的订单
        .item.mb.concat(@click="contact()") 联系客服
        .item.mb12.opinion(@click="opinion()") 意见反馈
        //- .item.mb12.out(@click="logout()") 退出登录
    foot
</template>

<style src="./index.styl" lang="stylus"></style>
<style src="../../stylus/reset.styl" lang="stylus"></style>


 

<script>
import foot from "@/components/footer.vue";
import { userDetail, weixinLogin, info, logout } from "../../config/request";
import { Empty } from 'vant';
import ShareImpl from '@/weixinApi.js';


export default {
    data() {
        return {
            head_img_url: "",
            username: "",
        };
    },
    components: {
        foot,
    },
    async created() {
        this.init();
    },
    methods: {
        order() {
            this.$router.push({ path: "/order" });
        },
        contact() {
            this.$router.push({ path: "/contact" });
        },
        opinion() {
            this.$router.push({ path: "/opinion" });
        },

        init() {
            this.getData();
        },

        getData() {
            info().then((res) => {
                
                console.log(res,'user11111');
                let shareUrl = window.location.href;
                // if(shareUrl.indexOf('from') != -1){
                //   shareUrl = window.location.href.split("?")[0];
                // }
                // window.console.log(url + 'favicon.png');
                console.log(res,11111);
                let img = window.location.href.split("#")[0] + 'd.jpg';
                let shareTitle = '米链谷川';
                let shareDesc = '个人中心';
                console.log(shareDesc,'分享title')
                let shareImg = img;
                // let shareImg = img;
                console.log(shareImg,'图片111')
                console.log(img,'本地111')
                ShareImpl({
                  shareTitle,
                  shareDesc,
                  shareUrl: location.origin + location.pathname + "?share=true/" + location.hash,
                  shareImg
                });
                this.head_img_url = res.data.info ? res.data.info.head_img_url : '';
                this.username = res.data.info ? res.data.info.username : '';
                
            });
        },






        /*tologin() {
            if (window.location.href.split("code=").length > 1) {
                this.code = window.location.href
                    .split("code=")[1]
                    .split("&state=")[0];
            } else {
                this.code = "";
            }
            if (this.code == "") {
                console.log("------wxlogin----");
                this.wxlogin();
            } else {
                console.log("-----login-----");
                this.login();
            }
        },

        wxlogin() {
            //const appId = "wx3fea410e2c24d247"; // 测试网
            //const appId = "wxd63cd34baea12b3f"; // 外网
            const appId = this.app_id
            const thisUrl = encodeURIComponent(window.location.href);
            const SCOPE = "snsapi_userinfo";
            const dates = new Date();
            const STATE = dates.getTime();

            const wxurl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${thisUrl}&response_type=code&scope=${SCOPE}&state=${STATE}#wechat_redirect`;
            window.location.href = wxurl;
            
        },

        login() {
            weixinLogin({ code: this.code }).then((res) => {
                console.log(res.code,"code");
                if (res.code == 20000) {
                    console.log("------登入----");
                    this.getData();
                }else{
                    console.log("------res----");
                    return false;
                }
                
            });
        },

        //退出登录
        logout() {
            logout().then((res) => {
                if (res.code == 20000) {
                    this.toast({ message: res.message });
                    this.code = "";
                    setTimeout(function () {
                        window.location.href =
                            res.data.data + "/mobile/index/index";
                    }, 500);
                }
            });
        },*/
    },
};
</script>
 